import Vue from 'vue'

// 引入element组件
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// 引入axios和vue-axios
// import axios from 'axios'
// import VueAxios from 'vue-axios'

// 引入qs插件
// import qs from 'qs'

// 引入国家旗帜图标
import './style/flag-icon-css-master/css/flag-icon.css'

// 引入iconfont图标的css文件
import './style/iconfont/iconfont.css'

// 导入初始化样式
import './style/base.css'

// 解决项目没有添加事件管理者’passive’，从而报黄色警告
import 'default-passive-events'

// 导入时间格式化
import moment from 'moment'
import momentTimezone from 'moment-timezone'

// // 获取token
import {
  getToken,
  removeToken
} from './utils/token'

import App from './App.vue'
import router from './router'
import store from './store'

// 解决两次点击相同路由时的报错
import Router from 'vue-router'
Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

// 导入时间格式化
Vue.filter('formatDate', function (value) {
  return moment(value).format('YYYY-MM-DD HH:mm:ss')
})
const momentTime = momentTimezone.tz.guess()
moment.tz.setDefault(momentTime)
Vue.prototype.$moment = moment
moment.locale('zh-cn')

// 注册element组件
Vue.use(ElementUI)

// 注册axios vue-axios
// Vue.use(VueAxios, axios)

// 将qs添加到原型链上
// Vue.prototype.$qs = qs

// 将jq添加到原型链上

Vue.config.productionTip = false

// 全局路由守卫
router.beforeEach((to, from, next) => {
  // // ('next ==>', next)
  if (to.path === '/F_home') { // 每次页面路由跳转的时候进行判断，如果是跳转到登陆界面就直接放行
    next()
  } else {
    const token = getToken()
    const newTime = Date.parse(new Date())
    const tokenTime = parseInt(localStorage.getItem('buyer-tokenTime'))
    // // (token)
    if (tokenTime && tokenTime < newTime) {
      removeToken()
      localStorage.removeItem('buyer-tokenTime')
      store.commit('getLoginbool', false)
      ElementUI.Message({
        message: 'Please click the top right corner to login',
        type: 'info'
      })
      next('/F_Login')
    }
    if (token === null && to.path.split('_')[0] === '/A') { // 如果是跳转到其他页面，先判断token是否为空或者是否存在，不存在就跳转到登陆界面重新登陆
      ElementUI.Message({
        message: 'Please click the top right corner to login',
        type: 'info'
      })
      next('/F_Login')
      const path = `/${window.location.href.split('/')[4]}`
      // ('path ==>', path)
    } else if (token === null && to.path.split('_')[0] === '/F') { // 如果没有token但是为展示页面给放行
      store.commit('getTokenbool', false)
      next()
    } else {
      store.commit('getTokenbool', true)
      next()
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
