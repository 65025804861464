import request from '../utils/http'

// 导入qs序列化参数
// import qs from 'qs'

// 用户注册验证邮箱
export function userRegister (data) {
  return request({
    url: '/user/userprofile/verify',
    method: 'post',
    data
  })
}
// 用户注册表单
export function userRegisterform ({
  data,
  code
}) {
  // const data = { obj, code }
  return request({
    url: `/user/userprofile/register/${code}`,
    method: 'post',
    data
  })
}
// 用户登录
export function userLogin (data) {
  // const data = qs.stringify(test)
  // ('data ==>', data)
  return request({
    url: '/user/userprofile/login',
    method: 'post',
    data
  })
}
// 查询个人资料
export function userQuery () {
  return request({
    url: '/user/userprofile',
    method: 'get',
    params: ''
  })
}
// 修改密码第一步
export function modifyEmail (data) {
  return request({
    url: '/user/userprofile/link',
    method: 'post',
    data
  })
}

// 修改密码第二步
export function modifyPassword (data) {
  return request({
    url: '/user/userprofile/update',
    method: 'put',
    data
  })
}

// 获取商品类别
export function getGoodtype () {
  return request({
    url: '/product/category',
    method: 'get',
    params: ''
  })
}

// 获取商品详情
export function getGooddetails ({
  productId
}) {
  return request({
    url: `${productId}`,
    method: 'get'
  })
}
// 获取折扣商品详情
export function conpouDetail ({
  productId
}) {
  return request({
    url: `/deal/product/${productId}`,
    method: 'get'
  })
}

// 获取折扣商品收藏信息
export function dealLike ({
  page,
  size
}) {
  return request({
    url: '/product/favorite/searchDeal',
    method: 'get',
    params: {
      page,
      size
    }
  })
}

// 获取赠品商品收藏信息
export function freeLike ({
  page,
  size
}) {
  return request({
    url: '/product/favorite/searchFree',
    method: 'get',
    params: {
      page,
      size
    }
  })
}

// 商品收藏
export function likeGood (productId) {
  // (productId)
  return request({
    url: `/product/favorite/${productId}`,
    method: 'post'
  })
}

// cancle商品收藏
export function unlikeGood (productId) {
  return request({
    url: `/product/favorite/${productId}`,
    method: 'delete'
  })
}
// 折扣发起商品请求
export function getDeal ({
  productId
}) {
  return request({
    url: `/deal/request/${productId}`,
    method: 'post'
  })
}

// 赠品发起商品请求
export function getGood ({
  id
}) {
  return request({
    url: `/freebies/request/${id}`,
    method: 'post'
  })
}
// 获取用户发送信息
export function sendMessage ({
  requestId
}) {
  return request({
    url: '/freebies/chat/sendMessage',
    method: 'get',
    params: {
      requestId
    }
  })
}

// F_home页面
// 热销商品
export function newProducts ({
  page,
  size,
  site
}) {
  return request({
    url: `/freebies/product/updateTime/${page}/${size}`,
    method: 'get',
    params: {
      page,
      size,
      site
    }
  })
}
// 无积分赠品
export function newFreebies ({
  page,
  size,
  site
}) {
  return request({
    url: `/freebies/product/${page}/${size}`,
    method: 'get',
    params: {
      page,
      size,
      site
    }
  })
}
// 有积分赠品
export function earnPoints ({
  page,
  size,
  site
}) {
  return request({
    url: `/freebies/product/freePoint/${page}/${size}`,
    method: 'get',
    params: {
      page,
      size,
      site
    }
  })
}
// 折扣商品
export function newDeaak ({
  name,
  page,
  size,
  site
}) {
  return request({
    url: '/product/search/deal',
    method: 'get',
    params: {
      name,
      page,
      size,
      site
    }
  })
}
// 折扣页面搜索
export function deatilSearch ({
  categoryId,
  discountHigherLimit,
  discountLowerLimit,
  page,
  priceHigherLimit,
  priceLowerLimit,
  site,
  size,
  sorting
}) {
  return request({
    url: '/product/search/trendingDeal',
    method: 'get',
    params: {
      categoryId,
      discountHigherLimit,
      discountLowerLimit,
      page,
      priceHigherLimit,
      priceLowerLimit,
      site,
      size,
      sorting
    }
  })
}
// 无积分赠品搜索
export function fressSearch ({
  category,
  highPrice,
  lowPrice,
  page,
  quantitySort,
  site,
  size,
  sort
}) {
  return request({
    url: `/freebies/product/search/${page}/${size}`,
    method: 'get',
    params: {
      category,
      highPrice,
      lowPrice,
      page,
      quantitySort,
      site,
      size,
      sort
    }
  })
}
// F_EARNPONINT搜索
export function earnpointSearch ({
  category,
  highPrice,
  lowPrice,
  page,
  pointSort,
  quantitySort,
  site,
  size,
  sort
}) {
  return request({
    url: `/freebies/product/freePoint/search/${page}/${size}`,
    method: 'get',
    params: {
      category,
      highPrice,
      lowPrice,
      page,
      pointSort,
      quantitySort,
      site,
      size,
      sort
    }
  })
}
// 顶部搜索栏
export function searchGood ({
  name,
  size,
  page,
  site,
  type
}) {
  return request({
    url: `${type}`,
    method: 'get',
    params: {
      name,
      size,
      page,
      site
    }
  })
}
// 主页面轮播图
export function bannerImg (country) {
  return request({
    url: 'freebies/product/findAllCarouselImage',
    method: 'get',
    params: { country }
  })
}
