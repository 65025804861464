<template>
  <div id="app">
    <!-- <router-view/> -->
    <!-- <keep-alive> -->
    <router-view></router-view>
    <!-- </keep-alive> -->
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  created () {
    // 解决页面刷新，vuex状态数据丢失问题
    // if (window.localStorage.getItem('list')) {
    //   this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(window.localStorage.getItem('list'))))
    // }
    // window.addEventListener('beforeunload', () => {
    //   window.localStorage.setItem('list', JSON.stringify(this.$store.state))
    // })
    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
    }
    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
    // 全局调用vuex mutations方法
    this.$store.commit('goodType')
  },
  methods: {
  }
}
</script>

<style lang="less">
* {
  list-style: none;
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  max-width: 1600px;
  margin: auto;
  // overflow: hidden;
}
//分页
.block {
  display: flex;
  justify-content: center;
  .blockDiv {
    display: inline-block;
    /deep/ .el-pagination {
      padding: 5px;
      margin-top: 20px;
    }
    /deep/.el-pagination__total {
      border: 1px solid #dcdfe6;
      margin: 0;
      padding: 5px;
      line-height: 18px;
    }
    /deep/ .btn-prev,
    /deep/ .btn-next {
      border: 1px solid #dcdfe6;
    }
    /deep/ .number {
      border: 1px solid #dcdfe6;
    }
    /deep/ .el-input__inner {
      height: 28px !important;
    }
  }
}
// 消息弹出层
.chatBox {
  .textBox {
    max-height: 200px;
    overflow-y: scroll;
    padding: 30px 15px;
    .chatLeft {
      display: flex;
      justify-content: flex-start;
      margin-top: 30px;
      .seller {
        // min-width: 50px;
        width: 65px;
        margin-right: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .sellerText {
        padding: 8px 15px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
        color: #76838f;
        background-color: #dfe9ef;
        position: relative;
        &::before {
          position: absolute;
          top: 10px;
          left: -20px;
          width: 0;
          height: 0;
          content: "";
          border: 10px solid transparent;
          border-right-color: #dfe9ef;
        }
        p {
          font-size: 16px;
          line-height: 20px;
        }
        .time {
          margin-top: 20px;
          color: #a3afb7;
        }
      }
    }
    .chatRight {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
      position: relative;
      .buyerText {
        padding: 8px 15px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
        color: white;
        background-color: #62a8ea;
        position: relative;
        right: 100px;
        &::before {
          position: absolute;
          top: 10px;
          right: -20px;
          width: 0;
          height: 0;
          content: "";
          border: 10px solid transparent;
          border-left-color: #62a8ea;
        }
        p {
          font-size: 16px;
          line-height: 20px;
        }
        .time {
          margin-top: 20px;
          color: #a3afb7;
        }
      }
      .buyer {
        position: absolute;
        width: 65px;
        margin-right: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .sendText {
    padding: 0 15px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .textarea {
      width: 100%;
    }
    .upload-demo {
      width: 100%;
      margin-left: 12px;
      min-height: 15px;
      margin-bottom: 20px;
      /deep/ .el-upload-list__item {
        float: left;
        max-width: 70px;
        max-height: 50px;
        padding: 0 20px 0 0;
        margin: 0 5px 0 0;
        /deep/ img {
          max-width: 50px;
          height: auto;
          margin-left: 0;
        }
      }
    }
  }
  .send {
    border-top: 1px solid #c8ced3;
    padding: 15px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
