import {
// eslint-disable-next-line no-unused-vars
  message
} from '../utils/resetMessage'
// 导入 获取token的方法
import {
  getToken,
  getTokenTime,
  removeToken
} from '../utils/token'

// 导入axios
import axios from 'axios'
// 导入element消息提醒
// import { Message } from 'element-ui'

import router from '../router'

const buyer = axios.create({
  baseURL: 'https://crediscounts.com/api'
  // headers: { 'content-type': 'application/x-www-form-urlencoded' }
})

// 不需要token可访问的接口
// const whitelistApi = [
//   'verify',
//   'register'
// ]

// 请求拦截器
buyer.interceptors.request.use(
  config => {
    // const configUrl = config.url.split('/')
    // if (whitelistApi.indexOf(configUrl[3]) === -1) {
    //   // ('添加token')
    //   config.headers.token = getToken()
    // }
    const url = '/user/userprofile/login'
    const token = getToken() // 获取token
    const tokenTime = getTokenTime() // 获取token过期时间戳
    const time = Date.now() // 获取当前时间戳
    if (tokenTime && tokenTime < time && url !== config.url) {
      removeToken()
      return router.push({ path: '/F_Login' })
    }
    if (token) {
      // // ('添加token')
      config.headers.Authorization = getToken()
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
buyer.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === 201) {
      message.error('fail')
    }
    return response
  },
  error => {
    const self = this
    // 对响应错误做点什么
    if (error.response.status === 401 && error.response.data.message === 'Authentication failed,please login again') {
      removeToken()
      message.error('Authentication failed, please login again')
      router.replace({ path: '/F_Login' })
      // router.push({ path: '/F_Login' })
      // window.location.href = '/#/F_Login'
    }
    return Promise.reject(error)
  }
)

export default buyer
