import request from '../utils/http'

// 用户修改个人信息
export function updetaUser (data) {
  return request({
    url: '/user/userprofile',
    method: 'put',
    data
  })
}

// 用户积分详情
export function pointDetail ({
  page,
  size,
  userId
}) {
  return request({
    url: `/points/userparticular/${userId}/${page}/${size}`,
    method: 'get',
    params: {
      page,
      size,
      userId
    }
  })
}
// 获取用户积分
export function userIntegral ({
  userId
}) {
  return request({
    url: `/points/userIntegral/${userId}`,
    method: 'get'
  })
}
// 折扣
export function dealList ({
  page,
  size
}) {
  return request({
    url: '/deal/request/buyer',
    method: 'get',
    params: {
      page,
      size
    }
  })
}
// 折扣-提交亚马逊订单
export function dealOrderNumber ({
  id,
  orderNumber
}) {
  return request({
    url: `/deal/request/${id}/order/${orderNumber}`,
    method: 'post'
  })
}
// 折扣-删除申请折扣
export function deleteDeal ({
  id
}) {
  return request({
    url: `/deal/request/${id} `,
    method: 'delete'
  })
}
// 赠品 获取赠品申请请求
export function freeGood ({
  page,
  size,
  status
}) {
  return request({
    url: `freebies/request/buyer/${page}/${size}`,
    method: 'get',
    params: {
      status
    }
  })
}
// 买家提交订单号
export function ordered ({
  id,
  orderNumber
}) {
  return request({
    url: `freebies/request/ordered/${id}`,
    method: 'post',
    params: {
      orderNumber
    }
  })
}
// 买家提交评论截图
export function reviewed ({
  id,
  reviewUrl
}) {
  return request({
    url: `freebies/request/reviewed/${id}`,
    method: 'post',
    params: {
      reviewUrl
    }
  })
}
// 买家提交退款截图
export function finishedOrder ({
  id
}) {
  return request({
    url: `freebies/request/finishedOrder/${id}`,
    method: 'post'
  })
}

// 查询所有订单请求
export function findAll ({
  productId
}) {
  return request({
    url: 'freebies/request/findRequestByProductId',
    method: 'get',
    params: {
      productId
    }
  })
}
// 按条件查询所有订单
export function search ({
  orderNumber,
  page,
  size,
  status
}) {
  return request({
    url: `freebies/request/buyer/search/${page}/${size}`,
    method: 'get',
    params: {
      orderNumber,
      status
    }
  })
}
// cancle赠品申请请求(买家已申请，卖家未同意)
export function cancelledOrder ({
  id
}) {
  return request({
    url: `freebies/request/cancelledOrder/${id}`,
    method: 'post'
  })
}
// cancle赠品申请请求(卖家已同意)
export function waitingToOrderCancelled ({
  id
}) {
  return request({
    url: `freebies/request/waitingToOrderCancelled/${id}`,
    method: 'post'
  })
}
// 提醒卖家接口
export function remind ({
  id,
  remindType
}) {
  return request({
    url: `freebies/request/remind/${id}`,
    method: 'post',
    params: {
      remindType
    }
  })
}
// 支付-paypal支付
export function paypal ({
  total
}) {
  return request({
    url: 'points/paypal',
    method: 'get',
    params: {
      total
    }
  })
}
// 支付-支付宝支付
export function create ({
  total
}) {
  return request({
    url: 'points/alipay/create',
    method: 'get',
    params: {
      total
    }
  })
}
// 体现接口
export function pointcashback (
  createReturnMoneyDto
) {
  return request({
    url: 'pointcashback',
    method: 'post',
    data: createReturnMoneyDto
  })
}
// 查询体现记录
export function seachPoint ({
  page,
  size
}) {
  return request({
    url: 'pointcashback',
    method: 'get',
    params: {
      page,
      size
    }
  })
}
// 添加投诉
export function orderNumber ({
  orderNumber,
  reportDto
}) {
  // (reportDto)
  return request({
    url: `freebies/report/orderNumber/${orderNumber}`,
    method: 'put',
    data: reportDto
  })
}
// 查询所有未读消息
export function allChat ({
  page,
  size
}) {
  return request({
    url: 'freebies/chat/buyer',
    method: 'get',
    params: {
      page,
      size
    }
  })
}
// 查询用户聊天未读消息
export function count ({
  freeRequestId
}) {
  return request({
    url: `freebies/chat/count/${freeRequestId}`,
    method: 'get'
  })
}
// 用户发送聊天信息
export function chat ({
  addVO
}) {
  return request({
    url: 'freebies/chat',
    method: 'post',
    data: addVO
  })
}
// 用户获取聊天信息
export function chatGet ({
  freeRequestId
}) {
  return request({
    url: `freebies/chat/get/${freeRequestId}`,
    method: 'get'
  })
}
// 赠品对应状态订单
export function findNotificationCounts () {
  return request({
    url: 'freebies/request/findNotificationCounts',
    method: 'get'
  })
}
// 用户查询投诉信息
export function findByUserId ({
  dto,
  page,
  size
}) {
  return request({
    url: `freebies/report/findByBuyerId?page=${page}&size=${size}`,
    method: 'post',
    data: dto
  })
}
// 用户查询自己的通知
export function usermessage ({
  userId,
  page,
  size
}) {
  return request({
    url: `message/usermessage/${userId}/${page}/${size}`,
    method: 'get'
  })
}
// 用户阅读通知
export function getUesrmessage ({
  id
}) {
  return request({
    url: `message/usermessage/user/${id}`,
    method: 'get'
  })
}
