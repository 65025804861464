import Vue from 'vue'
import Vuex from 'vuex'

// 获取token
import {
  getToken
} from '../utils/token'
// 引入接口
import {
  getGoodtype,
  userQuery
} from '../api/buyerFront'
import {
  findNotificationCounts // 赠品对应状态订单
} from '../api/buyerAfter'
// import { cosh } from 'core-js/core/number'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 判断是否有token
    tokenBool: false,
    // 登录成功
    loginSucc: false,
    // 存储用户登录信息
    userFrom: {},
    token: getToken(),
    changePath: '',
    // 选择国家
    countryList: 'flag-icon flag-icon-us',
    countrySplit: 'us',
    // 商品类型
    goodTypevalue: '1',
    goodTypelist: [],
    // 按钮加载
    loadingBool: false,
    // 赠品下单状态
    freeStatus: '1',
    freeStatusList: {
      request: '1', // 申请中
      waitingOrder: '2', // 等待下单
      ordered: '3', // 已下单
      orderedReview: '4', // 订单已通过，待留评
      reviewed: '5', // 以评论待审核
      paymentGo: '6', // 已返款待确认
      finished: '7', // 已完成
      cancelled: '8', // 已cancle
      sellerRejected: '9' // 已拒绝
    },
    // 提醒类型
    remindType: {
      examine: 'examine', //  提醒卖家 进行订单审核 对应按钮 提醒审核
      refund: 'refund' // 提醒卖家 提交退款截图 对应按钮 提醒退款
    },
    freebiesList: {
      name: 'Freebies',
      icon: 'el-icon-present',
      index: '2',
      list: [{
        listName: 'Search Freebies',
        listIcon: 'el-icon-search',
        indexName: '/F_freebies',
        indexId: 0
      },
      {
        listName: 'Requests',
        listIcon: 'el-icon-info',
        indexName: '/A_requests',
        indexId: 1
      },
      {
        listName: 'WaitingOrder',
        listIcon: 'el-icon-more-outline',
        indexName: '/A_toOrder',
        indexId: 2
      },
      {
        listName: 'Ordered ',
        listIcon: 'el-icon-more',
        indexName: '/A_ordered',
        indexId: 3
      },
      {
        listName: 'Submit review',
        listIcon: 'el-icon-circle-check',
        indexName: '/A_submitReview',
        indexId: 4
      },
      {
        listName: 'Reviewed',
        listIcon: 'el-icon-circle-check',
        indexName: '/A_reviewed',
        indexId: 5
      },
      {
        listName: 'Waiting',
        listIcon: 'el-icon-time',
        indexName: '/A_waiting',
        indexId: 6
      },
      {
        listName: 'Finished',
        listIcon: 'el-icon-trophy',
        indexName: '/A_finsihed',
        indexId: 7
      },
      {
        listName: 'Cancelled',
        listIcon: 'el-icon-circle-close',
        indexName: '/A_cancelled',
        indexId: 8
      },
      {
        listName: 'Seller Rejected',
        listIcon: 'el-icon-close',
        indexName: '/A_sellerRejected',
        indexId: 9
      },
      {
        listName: 'Review Favorites',
        listIcon: 'el-icon-document',
        indexName: '/A_ReviewFavorites',
        indexId: 0
      }
      ]
    },
    freeList: [],
    othersList: {
      name: 'Others',
      icon: 'el-icon-more-outline',
      index: '3',
      list: [{
        listName: 'Messages',
        listIcon: 'el-icon-bell',
        indexName: '/A_messages'
      },
      {
        listName: 'Chats',
        listIcon: 'el-icon-chat-line-round',
        indexName: '/A_chats'
      }
      ]
    },
    othList: [],
    // 个人用户
    username: '',
    // 获取赠品bool
    freeBool: false,
    // 获取折扣bool
    dealBool: false
  },
  mutations: {
    // 获取个人信息
    getUser (state) {
      state.username = localStorage.getItem('buyer-userName')
    },
    // 获取提醒条数
    getCount (state) {
      // 赠品对应状态订单
      findNotificationCounts().then(result => {
        state.freeList = []
        state.othList = []
        if (result.data.code === 200) {
          state.freebiesList.list.map(v => {
            // // ('v ===>', v)
            for (const key in result.data.data.dto_map) {
              if (parseInt(key) === v.indexId) {
                v.indexNumber = result.data.data.dto_map[key]
                if (v.indexNumber !== 0) {
                  v.indexBool = true
                } else {
                  v.indexBool = false
                }
              }
            }
            state.freeList.push(v)
          })
          state.othersList.list.map((v, index) => {
            if (index === 0) {
              v.indexNumber = result.data.data.message_count
            } else if (index === 1) {
              v.indexNumber = result.data.data.chat_count
            }
            if (v.indexNumber === 0) {
              v.indexBool = false
            } else {
              v.indexBool = true
            }
            state.othList.push(v)
          })
        }
      }).catch(err => {
        return err
      })
    },
    // 更改loginBool
    getLoginbool (state, bool) {
      state.loginSucc = bool
      // ('state.loginSucc ==>', state.loginSucc)
    },
    // 更改tokenBool
    getTokenbool (state, bool) {
      state.tokenBool = bool
    },
    // 更改赠品请求状态
    getFreestatus (state, status) {
      state.freeStatus = status
    },
    // 获取商品类型
    goodType (state) {
      getGoodtype({}).then(result => {
        if (result.data.code === 200) {
          state.goodTypelist = result.data.data
        }
      })
    },
    // 国家选择
    test (state) {
      // ('state.countryList ==>', state.countryList)
      state.countryList = window.localStorage.getItem('buyer-countyr')
      state.countrySplit = window.localStorage.getItem('buyer-countyr').split('-')[3]
    },
    // 更改赠品bool
    getFreebool (state, bool) {
      state.freeBool = bool
    },
    // 更改折扣bool
    getDealbool (state, bool) {
      state.dealBool = bool
    },
    // 更改加载
    getLoading (state, bool) {
      state.loadingBool = bool
    }
  },
  actions: {},
  modules: {}
})
