import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/F_home'
  },
  {
    path: '/sellerCenter',
    name: 'sellerCenter',
    component: res => require(['../views/sellerCenter'], res)
  },
  {
    path: '/F_register',
    name: 'F_register',
    component: res => require(['../views/F_register'], res)
  },
  {
    path: '/buyerFront',
    name: 'buyerFront',
    component: res => require(['@/views/buyerFront'], res),
    children: [
      {
        path: '/F_login',
        name: 'F_login',
        component: res => require(['../views/F_login'], res)
      },
      {
        path: '/F_home',
        name: 'F_home',
        component: res => require(['../views/F_home'], res)
      },
      {
        path: '/F_reset',
        name: 'F_reset',
        component: res => require(['../views/F_reset'], res)
      },
      {
        path: '/F_password',
        name: 'F_password',
        component: res => require(['../views/F_password'], res)
      },
      {
        path: '/F_point',
        name: 'F_point',
        component: res => require(['../views/F_point'], res)
      },
      {
        path: '/F_work',
        name: 'F_work',
        component: res => require(['../views/F_work'], res)
      },
      {
        path: '/F_deals',
        name: 'F_deals',
        component: res => require(['../views/F_deals'], res)
      },
      {
        path: '/F_freebies',
        name: 'F_freebies',
        component: res => require(['../views/F_freebies'], res)
      },
      {
        path: '/F_earnPoints',
        name: 'F_earnPoints',
        component: res => require(['../views/F_earnPoints'], res)
      },
      {
        path: '/F_buyerFAQ',
        name: 'F_buyerFAQ',
        component: res => require(['../views/F_buyerFAQ'], res)
      },
      {
        path: '/F_sellerFAQ',
        name: 'F_sellerFAQ',
        component: res => require(['../views/F_sellerFAQ'], res)
      },
      {
        path: '/F_about',
        name: 'F_about',
        component: res => require(['../views/F_about'], res)
      },
      {
        path: '/F_privacy',
        name: 'F_privacy',
        component: res => require(['../views/F_privacy'], res)
      },
      {
        path: '/F_terms',
        name: 'F_terms',
        component: res => require(['../views/F_terms'], res)
      },
      {
        path: '/F_dealDetails',
        name: 'F_dealDetails',
        component: res => require(['../views/F_dealDetails'], res)
      },
      {
        path: '/F_freeDetails',
        name: 'F_freeDetails',
        component: res => require(['../views/F_freeDetails'], res)
      },
      {
        path: '/F_seachDetails',
        name: 'F_seachDetails',
        component: res => require(['../views/F_seachDetails'], res)
      },
      // {
      //   path: '/F_freebiesDetails',
      //   name: 'F_freebiesDetails',
      //   component: res => require(['../views/F_freebiesDetails'], res)
      // },
      {
        path: '/F_getConpou',
        name: 'F_getConpou',
        component: res => require(['../views/F_getConpou'], res)
      },
      {
        path: '/F_sellerguide',
        name: 'F_sellerguide',
        component: res => require(['../views/F_sellerguide'], res)
      }
    ]
  },
  {
    path: '/buyerAfter',
    name: 'buyerAfter',
    component: res => require(['../views/buyerAfter'], res),
    children: [
      {
        path: '/A_user',
        name: 'A_user',
        component: res => require(['../views/A_user'], res)
      },
      {
        path: '/A_scoreDetail',
        name: 'A_scoreDetail',
        component: res => require(['../views/A_scoreDetail'], res)
      },
      {
        path: '/A_dashBoard',
        name: 'A_dashBoard',
        component: res => require(['../views/A_dashBoard'], res)
      },
      {
        path: '/A_dealsRequests',
        name: 'A_dealsRequests',
        component: res => require(['../views/A_dealsRequests'], res)
      },
      {
        path: '/A_dealFavorites',
        name: 'A_dealFavorites',
        component: res => require(['../views/A_dealFavorites'], res)
      },
      {
        path: '/A_requests',
        name: 'A_requests',
        component: res => require(['../views/A_requests'], res)
      },
      {
        path: '/A_toOrder',
        name: 'A_toOrder',
        component: res => require(['../views/A_toOrder'], res)
      },
      {
        path: '/A_ordered',
        name: 'A_ordered',
        component: res => require(['../views/A_ordered'], res)
      },
      {
        path: '/A_submitReview',
        name: 'A_submitReview',
        component: res => require(['../views/A_submitReview'], res)
      },
      {
        path: '/A_reviewed',
        name: 'A_reviewed',
        component: res => require(['../views/A_reviewed'], res)
      },
      {
        path: '/A_waiting',
        name: 'A_waiting',
        component: res => require(['../views/A_waiting'], res)
      },
      {
        path: '/A_finsihed',
        name: 'A_finsihed',
        component: res => require(['../views/A_finsihed'], res)
      },
      {
        path: '/A_cancelled',
        name: 'A_cancelled',
        component: res => require(['../views/A_cancelled'], res)
      },
      {
        path: '/A_sellerRejected',
        name: 'A_sellerRejected',
        component: res => require(['../views/A_sellerRejected'], res)
      },
      {
        path: '/A_reviewFavorites',
        name: 'A_reviewFavorites',
        component: res => require(['../views/A_reviewFavorites'], res)
      },
      {
        path: '/A_messages',
        name: 'A_messages',
        component: res => require(['../views/A_messages'], res)
      },
      {
        path: '/A_chats',
        name: 'A_chats',
        component: res => require(['../views/A_chats'], res)
      },
      {
        path: '/A_myPoints',
        name: 'A_myPoints',
        component: res => require(['../views/A_myPoints'], res)
      },

      {
        path: '/A_pointCashback',
        name: 'A_pointCashback',
        component: res => require(['../views/A_pointCashback'], res)
      },
      {
        path: '/A_report',
        name: 'A_report',
        component: res => require(['../views/A_report'], res)
      },
      {
        path: '/A_reportDetail',
        name: 'A_reportDetail',
        component: res => require(['../views/A_reportDetail'], res)
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes,
  // 解决路由跳转不回归顶部
  scrollBehavior (to, from, saveTop) {
    if (saveTop) {
      return saveTop
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

export default router
