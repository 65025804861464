// 设置一个固定的key值
const KEY = 'buyerKey'

const TIME = 'buyer-tokenTime'

const NAME = 'buyer-userName'

// 暴露三个方法

// 设置token
export function setToken (token) {
  window.localStorage.setItem(KEY, token)
}

// 获取token
export function getToken () {
  return window.localStorage.getItem(KEY)
}

// 获取token过期时间戳
export function getTokenTime () {
  return window.localStorage.getItem(TIME)
}

// 删除token
export function removeToken () {
  // 根据key,删除指定的值
  window.localStorage.removeItem(KEY)
  // 删除时间
  window.localStorage.removeItem(TIME)
  // 清除名称
  window.localStorage.removeItem(NAME)
}
